<script setup>
import { usePage } from '@inertiajs/vue3';
import { ref } from 'vue';
import { onErrorCaptured } from 'vue';
import { isEqual, isEmpty } from 'lodash';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import { Dropdown } from '@/company/Components';
import { onUnmounted, computed, onMounted } from 'vue';
import ErrorModal from './ErrorModal.vue';

const props = defineProps({
    modelValue: { type: Object, default: null },
    as: { type: [Object, Function, String], default: null },
    message: { type: String, default: null },
});

const debugMode = computed(() =>
    import.meta.env.VITE_DEBUG_CLIENT.toLowerCase() !== 'false'
        ? import.meta.env.DEV || import.meta.env.VITE_DEBUG_CLIENT.toLowerCase() === 'true'
        : false,
);

const error = ref(props?.modelValue);

const receivedValidationErrors = ref(false);
const validationErrors = ref({});

onErrorCaptured((err) => {
    const errors = validationErrors.value;
    if (!receivedValidationErrors.value) {
        error.value = err;
        console.error(error.value);
    } else {
        // if (!isEmpty(errors)) {
        console.error('Validation error:', err?.response?.data?.errors ?? usePage().props?.errors);
        // }
    }
    return false;
});

const errorListener = ref(null);
onMounted(() => {
    errorListener.value = (e) => {
        receivedValidationErrors.value = true;
        validationErrors.value = e.details?.errors ?? {};
        setTimeout(() => {
            receivedValidationErrors.value = false;
        });
    };
    document.addEventListener('inertia:error', errorListener.value);
});

onUnmounted(() => {
    document.removeEventListener('inertia:error', errorListener.value);
});
</script>
<template>
    <template v-if="error">
        <ErrorModal v-if="debugMode" v-bind="{ error }" />
        <template v-if="as">
            <component :is="as" v-bind="{ error }" />
        </template>
        <template v-else>
            <slot name="before" />
            <Dropdown
                content-classes="pt-3 w-auto bg-white overflow-hidden bg-palmers-white border-2 border-white/20"
                popper-classes="top-1"
                :align="`center`"
                class="inline-block"
                arrow
            >
                <template #trigger>
                    <button
                        type="button"
                        class="flex w-full cursor-help items-center space-x-2 focus:bg-black/5 supports-hover:hover:bg-black/5"
                    >
                        <ExclamationTriangleIcon class="h-5 w-5" />
                        <div>{{ message ?? 'Something went wrong' }}</div>
                    </button>
                </template>
                <template #content>
                    <div class="inline-block w-full space-y-3 text-black">
                        <div class="w-full space-y-1 py-3 text-center">{{ error }}</div>
                    </div>
                </template>
            </Dropdown>
            <slot name="after" />
        </template>
    </template>
    <template v-else>
        <slot />
    </template>
</template>
