import { onMounted } from "vue";

export default function (callback) {
    const handleAnimationFrame = () => {
        try {
            callback()
            requestAnimationFrame(handleAnimationFrame)
        } catch(error) {
            throw error
        }
    }

    onMounted(() => {
        handleAnimationFrame()
    })
}
