<script setup>
import { Modal } from '@/common/Components';
import ActionButton from '@/company/Components/Buttons/ActionButton.vue';
import { computed, ref } from 'vue';

const props = defineProps({
    error: { type: Object, default: null },
});

const message = computed(() => props.error.message ?? props.error);

const trace = computed(
    () =>
        props.error.trace ??
        props.error.stack
            .split('\n')
            .slice(1)
            .map((x) => x.trim()),
);

const show = ref(true);
</script>
<template>
    <Modal :show="show" max-width="screen-lg" @close="show = false">
        <div
            class="space-y-6 border-t-8 border-t-red-600 bg-stone-950 p-6 font-mono leading-loose text-white"
        >
            <div>
                <div class="font-bold text-rose-500">[Error]</div>
                <div>{{ message }}</div>
            </div>
            <div>
                <div class="font-bold text-fuchsia-400">[Trace]</div>
                <div class="overflow-scroll text-sm">
                    <div v-for="line in trace" class="whitespace-pre">{{ line }}</div>
                </div>
            </div>

            <hr class="border border-dotted border-gray-800" />
            <div class="flex items-center justify-end">
                <ActionButton @click="show = false" type="danger"> Close </ActionButton>
            </div>
        </div>
    </Modal>
</template>
