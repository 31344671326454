<script setup>
import 'tippy.js/dist/tippy.css';
// import 'tippy.js/themes/light.css'
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { directive as vClickAway } from 'vue3-click-away';
import { twMerge } from 'tailwind-merge';
import { onAnimationFrame } from '@/company/Composables';
import { Tippy } from 'vue-tippy';

const props = defineProps({
    align: {
        type: String,
        default: 'right',
    },
    width: {
        type: String,
        default: '48',
    },
    contentClasses: {
        type: String,
        default: 'bg-white',
    },
    popperClasses: {
        type: String,
        default: '',
    },
    arrow: {
        type: Boolean,
        default: false,
    },
    open: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const closeOnEscape = (e) => {
    if (open.value && e.key === 'Escape') {
        open.value = false;
    }
};

onMounted(() => document.addEventListener('keydown', closeOnEscape));
onUnmounted(() => document.removeEventListener('keydown', closeOnEscape));

const widthClass = computed(() => {
    return {
        48: 'w-48',
        72: 'w-80',
    }[props.width.toString()];
});

const alignmentClasses = computed(() => {
    if (props.align === 'left') {
        return 'origin-top-left left-0';
    } else if (props.align === 'right') {
        return 'origin-top-right right-0';
    } else if (props.align === 'center') {
        return 'origin-top-right left-1/2 -translate-x-1/2';
    } else {
        return 'origin-top';
    }
});

const arrowClass = computed(() => {
    const className = ['dropdown-arrow'];
    if (props.align === 'left') {
        className.push('before:left-3 after:left-3');
    } else if (props.align === 'right') {
        className.push('before:right-3 after:right-3');
    } else if (props.align === 'center') {
        className.push(
            'before:left-1/2 after:left-1/2 before:-translate-x-1/2 after:-translate-x-1/2',
        );
    } else {
        return;
    }

    return className.join(' ');
});

const popperClasses = computed(() => props.popperClasses);

const body = ref(null);
const open = ref(!props.disabled && props.open);
const contentWrapper = ref(null);
const contentBackgroundColor = ref(null);

const close = () => {
    open.value = false;
};

const show = () => {
    if (!props.disabled) {
        open.value = true;
    }
};

onAnimationFrame(() => {
    contentBackgroundColor.value = contentWrapper.value
        ? window.getComputedStyle(contentWrapper.value, null).getPropertyValue('background-color')
        : null;
});

onMounted(() => {
    body.value = document.body;
});

const showPopper = () => open.value;
</script>

<template>
    <Tippy
        class="relative"
        animation="shift-away"
        arrow-type="round"
        :arrow="true"
        :follow-cursor="false"
        :duration="0"
        :delay="0"
        :append-to="() => body"
        :interactive="true"
        :allowHTML="true"
        placement="bottom"
        @show="showPopper"
        trigger="click"
    >
        <div @click="show">
            <slot name="trigger" />
        </div>

        <template #content>
            <div
                ref="contentWrapper"
                :class="
                    twMerge(
                        'w-full rounded-md shadow-lg ring-1 ring-black  ring-opacity-5',
                        contentClasses,
                    )
                "
            >
                <slot name="content" />
            </div>
        </template>
    </Tippy>
</template>

<style scoped lang="css">
.dropdown-arrow::before,
.dropdown-arrow::after {
    content: '';
    @apply absolute block h-3 w-3;
    bottom: calc(100% - 2px);
    border-left: theme('width.3') solid transparent;
    border-right: theme('width.3') solid transparent;
    border-bottom: theme('height.3') solid transparent;
}

.dropdown-arrow:before {
    border-left: calc(theme('width.3') + 1px) solid transparent;
    border-right: calc(theme('width.3') + 1px) solid transparent;
    border-bottom: calc(theme('height.3') + 2px) solid transparent;
    border-bottom-color: theme('colors.gray.100');
}

.dropdown-arrow:after {
    @apply z-[60];
    border-bottom-color: v-bind(contentBackgroundColor);
}

.tippy-arrow {
    color: v-bind(contentBackgroundColor);
}
</style>
